import { render, staticRenderFns } from "./ImageCarousel.vue?vue&type=template&id=b471ee6e&"
import script from "./ImageCarousel.vue?vue&type=script&lang=js&"
export * from "./ImageCarousel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b471ee6e')) {
      api.createRecord('b471ee6e', component.options)
    } else {
      api.reload('b471ee6e', component.options)
    }
    module.hot.accept("./ImageCarousel.vue?vue&type=template&id=b471ee6e&", function () {
      api.rerender('b471ee6e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8KAx5TUgYsFP6/JS/ImageCarousel.vue"
export default component.exports